import { component } from '~/utilities/web-components';
import { ActionEvent } from './x-dispatch';

const Component = component('x-emit-event')<{ eventName: string; action?: 'open' | 'close' }>((self, attributes) => {
  self.dispatchEvent(new ActionEvent(attributes.eventName, attributes.action));
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
