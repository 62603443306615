import { component } from '~/utilities/web-components';

const Component = component('x-select-value')((self) => {
  const target = self.previousSibling;
  if (!(target instanceof HTMLSelectElement || target instanceof HTMLInputElement)) {
    return console.warn('Cannot locate select element');
  }
  const setValue = () => target.parentElement?.setAttribute('value', target.value);
  target.addEventListener('change', setValue);
  setValue();
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
