import { component } from '~/utilities/web-components';
import './x-slider.css';

const Component = component('x-slider')<{ slidesPerView?: number }>(async (self, attributes) => {
  const [{ Swiper }, { Navigation, Pagination }] = await Promise.all([import('swiper'), import('swiper/modules')]);

  new Swiper(self, {
    grabCursor: true,
    modules: [Navigation, Pagination],
    pagination: {
      el: self.querySelector<HTMLElement>('.swiper-pagination'),
      dynamicBullets: true,
      type: attributes.slidesPerView ? 'progressbar' : 'bullets',
    },
    navigation: {
      nextEl: self.querySelector<HTMLElement>('.swiper-button-next'),
      prevEl: self.querySelector<HTMLElement>('.swiper-button-prev'),
    },
    slidesPerView: attributes.slidesPerView ? Number.parseInt(attributes.slidesPerView) : 1,
    spaceBetween: attributes.slidesPerView ? 16 : 0,
  });
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
