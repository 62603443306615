import { IElbwalker, WebDestination } from '@elbwalker/walker.js';
import { openDB, IDBPDatabase, DBSchema } from 'idb';

export declare namespace DestinationIndexDB {
  interface Function extends WebDestination.Function<CustomConfig> {}

  type Config = WebDestination.Config<CustomConfig>;

  interface CustomConfig {
    dbName: string;
    dbVersion: number;
    dbCollection: string;
  }

  interface CustomEventConfig {
    timestamp: string;
  }
}

interface HitDB extends DBSchema {
  hits: {
    key: string;
    value: IElbwalker.Event;
    indexes: {
      date: number;
    };
  };
}

const useDb = async (callback: (db: IDBPDatabase<HitDB>) => unknown | Promise<unknown>) => {
  const db = await openDB<HitDB>('tracking', 2, {
    upgrade: (db) => db.createObjectStore('hits', { keyPath: 'id' }).createIndex('date', 'timestamp'),
  });
  await callback(db);
  db.close();
};

const destinationIndexDB: DestinationIndexDB.Function = {
  config: {},
  push: (event: IElbwalker.Event) => useDb((db) => db.add('hits', event)),
};

export default destinationIndexDB;
