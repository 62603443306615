import { component } from '~/utilities/web-components';
import { visible } from './utilities/visible';

let used = false;
const Component = component('x-conditional-filter')(async (self) => {
  // To avoid flickering while the filter sidebar is open:
  if (!used) {
    await visible(self);
  }
  const filter = document.querySelector('[data-key="filter"]')?.cloneNode(true);
  if (!filter) {
    console.error('Could not find filter element');
    return;
  }
  self.replaceWith(filter);
  used = true;
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
