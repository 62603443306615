import { ActionEvent } from '~/assets/components/x-dispatch';
import { component } from '~/utilities/web-components';

const isFormFocused = () => {
  const activeEl = document.activeElement;
  return Boolean(activeEl && (activeEl.tagName === 'INPUT' || activeEl.tagName === 'TEXTAREA'));
};

const Component = component('x-keyboard-trigger')<{
  toggleEvent: string;
  keyPress: string;
}>((self, attributes) => {
  window.addEventListener('keypress', (event) => {
    if (event.key === attributes.keyPress && !isFormFocused()) {
      self.dispatchEvent(new ActionEvent(attributes.toggleEvent, 'open'));
    }
  });
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
