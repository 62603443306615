import { component } from '~/utilities/web-components';

const Component = component('x-store-algolia-data')<{ queryId: string; indexName: string }>((_self, attributes) => {
  sessionStorage.setItem('queryId', attributes.queryId);
  sessionStorage.setItem('indexName', attributes.indexName);
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
