import { onLCP, onFID, onCLS, onTTFB, onINP, onFCP } from 'web-vitals';

const webVitals: Record<string, string> = { routePath: document.body.dataset.routePath! };

[onCLS, onFID, onLCP, onTTFB, onINP, onFCP].forEach((f) => f(({ name, value }) => (webVitals[name] = value + '')));

document.addEventListener(
  'visibilitychange',
  () => {
    if (document.visibilityState === 'hidden') {
      navigator.sendBeacon('/_web-vitals', new URLSearchParams(webVitals));
    }
  },
  { once: true },
);
