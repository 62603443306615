import { component } from '~/utilities/web-components';
import { debounce } from './utilities/debounce';

const Component = component('x-submit-onchange')<{ debounce?: number }>((self, attributes) => {
  const { form } = self.attachInternals();
  const submit = () => form?.requestSubmit();
  form?.addEventListener(
    'change',
    attributes.debounce ? debounce(submit, Number.parseInt(attributes.debounce)) : submit,
  );
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
