export const slugify = (text: string) =>
  text
    .toString()
    .normalize('NFKD')
    .toLowerCase()
    .trim()
    .replaceAll(/\s+/gu, '-')
    .replaceAll(/[^\w-]+/gu, '')
    .replaceAll('_', '-')
    .replaceAll(/--+/gu, '-')
    .replaceAll(/-$/gu, '');
