import { component } from '~/utilities/web-components';
import type { ChartConfiguration } from 'chart.js';
import { slugify } from '~/utilities/slugify';
import { visible } from './utilities/visible';
import { openDB } from 'idb';

const DB_NAME = 'tracking';

const getRandomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  return `rgb(${r}, ${g}, ${b})`;
};

const getColorsCombo = (length: number) => {
  const colors: string[] = [];

  if (colors) {
    for (let index = 0; index < length; index++) {
      colors.push(getRandomColor());
    }
  }

  return colors;
};

const Component = component('x-chart')<{ configKey: string }>(async (self, attributes) => {
  await visible(self);
  const { Chart, registerables } = await import('chart.js');
  Chart.register(...registerables);

  const db = await openDB(DB_NAME, 2);

  // Preferred Category
  const preferredCategories = (await db.getAllFromIndex('hits', 'date')).reduce((agg, hit) => {
    if (hit.event == 'category view' && hit.source && hit.source.id.includes('/collections/')) {
      const lastSlugFromCollection = hit.source.id.split('/').pop();
      const categories = lastSlugFromCollection.split('?').shift();
      const secondLevelCategory = categories.split('-');

      if (!agg[secondLevelCategory[0]]) {
        agg[secondLevelCategory[0]] = {};
      }

      secondLevelCategory.forEach((_element: any, key: number) => {
        if (key > 0) {
          if (!agg[secondLevelCategory[0]][secondLevelCategory[key]]) {
            agg[secondLevelCategory[0]][secondLevelCategory[key]] = 0;
          }
          agg[secondLevelCategory[0]][secondLevelCategory[key]]++;
        }
      });
    }

    return agg;
  }, []);

  type Data = number[];
  interface DatasetForCategory {
    label: string;
    data: Data;
  }

  const datasetsCategoryLabels = [
    ...new Set(
      (Object.values(preferredCategories) as Record<string, number>[]).flatMap((obj) => Object.keys(obj)).flat(),
    ),
  ];

  const datasetsCategories: DatasetForCategory[] = Object.entries<Record<string, Record<string, number>>>(
    preferredCategories,
  ).map((entry) => {
    const mergedData = datasetsCategoryLabels.map((label: string) => {
      let value = 0;

      for (const key in entry[1]) {
        if (key == label) {
          value = Number(entry[1][key]);
        }
      }

      return value;
    });
    return { label: entry[0], data: mergedData };
  });

  const categoryConfig: ChartConfiguration = {
    type: 'radar',
    data: {
      labels: datasetsCategoryLabels,
      datasets: datasetsCategories,
    },
    options: {
      elements: {
        line: {
          borderWidth: 3,
        },
      },
    },
  };

  // Preferred Color
  const preferredColors = (await db.getAllFromIndex('hits', 'date')).reduce((agg, hit) => {
    if (hit.data?.color) {
      const color = slugify(hit.data.color);
      if (Array.isArray(hit.data.color)) {
        hit.data.color.forEach((currentColor: string) => {
          const color = slugify(currentColor);
          agg[color] = (agg[color] ?? 0) + 1;
        });
      } else {
        agg[color] = (agg[color] ?? 0) + 1;
      }
    }
    return agg;
  }, []);

  const colorConfig: ChartConfiguration = {
    type: 'line',
    data: {
      labels: Object.keys(preferredColors),
      datasets: [
        {
          label: 'Last Checked Color',
          data: Object.values(preferredColors),
          backgroundColor: getColorsCombo(Object.values(preferredColors).length),
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  // Preferred Size
  const preferredSizes = (await db.getAllFromIndex('hits', 'date')).reduce((agg, hit) => {
    if (hit.data?.size) {
      if (Array.isArray(hit.data.size)) {
        hit.data.size.forEach((currentSize: string | number) => {
          agg[currentSize] = (agg[currentSize] ?? 0) + 1;
        });
      } else {
        agg[hit.data.size] = (agg[hit.data.size] ?? 0) + 1;
      }
    }
    return agg;
  }, []);

  const sizeConfig: ChartConfiguration = {
    type: 'pie',
    data: {
      labels: Object.keys(preferredSizes),
      datasets: [
        {
          label: 'Last Checked Size',
          data: Object.values(preferredSizes),

          backgroundColor: getColorsCombo(Object.values(preferredSizes).length),
          hoverOffset: 4,
        },
      ],
    },
  };

  // Preferred Brand
  const preferredBrands = (await db.getAllFromIndex('hits', 'date')).reduce((agg, hit) => {
    if (hit.data?.brand) {
      if (Array.isArray(hit.data.brand)) {
        hit.data.brand.forEach((currentBrand: string | number) => {
          agg[currentBrand] = (agg[currentBrand] ?? 0) + 1;
        });
      } else {
        agg[hit.data.brand] = (agg[hit.data.brand] ?? 0) + 1;
      }
    }
    return agg;
  }, []);

  const brandConfig: ChartConfiguration = {
    type: 'polarArea',
    data: {
      labels: Object.keys(preferredBrands),
      datasets: [
        {
          label: 'Last Checked Brand',
          data: Object.values(preferredBrands),
          backgroundColor: getColorsCombo(Object.values(preferredBrands).length),
        },
      ],
    },
  };

  const getConfig = (key: string) => {
    if (key) {
      switch (key) {
        case 'color': {
          return colorConfig;
        }
        case 'size': {
          return sizeConfig;
        }
        case 'brand': {
          return brandConfig;
        }
        case 'category': {
          return categoryConfig;
        }
      }
    }

    return colorConfig;
  };

  const pieChartCanvas = self.querySelector<HTMLCanvasElement>(`canvas`);
  if (pieChartCanvas) {
    new Chart(pieChartCanvas, getConfig(attributes.configKey));
  }
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
