import { component } from '~/utilities/web-components';
import { elb } from '@elbwalker/walker.js';

//This will push the current session to elbwalker. Please note that apparently
//elbwalker uses id, device and hash in order, so if you push id and hash, only id
//will be used
const Component = component('x-user-push')<{ id?: string; device?: string; hash?: string }>((_self, attributes) => {
  elb('walker user', { id: attributes.id, device: attributes.device, hash: attributes.hash });
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
