import { WebDestination } from '@elbwalker/walker.js';
import aa from 'search-insights';

// eslint-disable-next-line no-secrets/no-secrets
const ID_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateRandomId = () =>
  Array.from({ length: 32 }, () => ID_ALPHABET.charAt(Math.floor(Math.random() * ID_ALPHABET.length))).join('');

type AlgoliaWalkerDestinationConfig = {
  credentials: { appId: string; apiKey: string };
  userToken: string;
};

const algoliaWalkerDestination: WebDestination.Function<AlgoliaWalkerDestinationConfig> = {
  config: {},
  init: ({ custom }) => {
    aa('init', custom!.credentials);
    return true;
  },
  push: ({ event, data, user }, config) => {
    const baseEvent = { eventName: event, userToken: config.custom!.userToken, authenticatedUserToken: user.id };
    switch (event) {
      case 'product click': {
        // eslint-disable-next-line no-secrets/no-secrets
        aa('clickedObjectIDsAfterSearch', {
          ...baseEvent,
          index: data.index as string,
          queryID: data.queryId as string,
          objectIDs: [data.id as string],
          positions: [data.position as number],
        });
        break;
      }
      case 'category view': {
        // only track clicked filters
        if (Object.keys(data).length > 0) {
          aa('clickedFilters', {
            ...baseEvent,
            index: sessionStorage.getItem('indexName') as string,
            filters: Object.entries(data).flatMap(([key, values]) =>
              (values as Array<string>).map((value) => `${encodeURI(key)}:${encodeURI(value)}`),
            ),
          });
        }
        break;
      }
      case 'product detail':
      case 'product impression': {
        aa('viewedObjectIDs', {
          ...baseEvent,
          index: sessionStorage.getItem('indexName') as string,
          objectIDs: [data.id as string],
        });
        break;
      }
      case 'product add': {
        // eslint-disable-next-line no-secrets/no-secrets
        aa('addedToCartObjectIDsAfterSearch', {
          ...baseEvent,
          index: sessionStorage.getItem('indexName') as string,
          objectIDs: [data.id as string],
          queryID: sessionStorage.getItem('queryId') as string,
        });
        break;
      }
    }
  },
};

export default algoliaWalkerDestination;
