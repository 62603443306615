import '@hotwired/turbo';
import * as amplitude from '@amplitude/analytics-browser';
import Plausible, { type EventOptions } from 'plausible-tracker';
import Elbwalker, { elb } from '@elbwalker/walker.js';
import destinationIndexDB from './components/utilities/indexdb-walker-destination';
import algoliaWalkerDestination, { generateRandomId } from './components/utilities/algolia-walker-destination';
import { setFavColorCookie } from './components/utilities/set-fav-color-cookie';
import './components/x-contact-form';
import './components/x-conditional-filter';
import './components/x-dispatch';
import './components/x-emit-event';
import './components/x-keyboard-trigger';
import './components/x-price-filter';
import './components/x-redirect';
import './components/x-select-value';
import './components/x-slider';
import './components/x-submit-onchange';
import './components/x-tabs';
import './components/x-toggle';
import './components/x-user-push';
import './components/x-variant-selector';
import './components/x-chart';
import './components/x-store-algolia-data';
import 'bundle:tailwind.css';
import './base.css';
import './utilities/web-vitals';
import './utilities/errors';

// TODO: Define domain in config
const plausibleConfig = {
  domain: process.env.NODE_ENV == 'development' ? 'localhost' : 'etribes-velocity.fly.dev',
};
const { trackEvent, trackPageview } = Plausible(plausibleConfig);

elb('walker destination', destinationIndexDB);
//manually add plausible
elb('walker destination', {
  config: {},
  init: () => true,
  push: (event) => {
    //skip product impressions because they kill our quota
    if (event.event === 'product impression') return;
    event.event === 'page view'
      ? trackPageview(plausibleConfig, {
          props: event.data as EventOptions['props'],
        })
      : trackEvent(
          event.event,
          {
            props: event.data as EventOptions['props'],
          },
          plausibleConfig,
        );
  },
});
elb('walker destination', algoliaWalkerDestination, {
  custom: {
    credentials: { appId: '5FGVYEDX6G', apiKey: '6a216958e197987bbdbc01bc4e42c875' },
    userToken: generateRandomId(),
  },
});
if (process.env.NODE_ENV === 'test') {
  //during tests we pluck events from console logs
  // eslint-disable-next-line no-console
  elb('walker destination', { config: {}, init: () => true, push: console.log });
}
// elb('walker destination', { config: {}, init: () => true, push: console.log }); // Demo destination for console.log

addEventListener('turbo:load', () => addEventListener('turbo:load', () => elb('walker run')), { once: true });
addEventListener('turbo:load', setFavColorCookie);

window.elbwalker = Elbwalker({ default: true, pageview: true });
amplitude.init('e48df13540718822b2e782c815fba957', { defaultTracking: true });

const { documentElement } = document;
if (process.env.NODE_ENV === 'development') {
  document.addEventListener('DOMContentLoaded', () => {
    if (sessionStorage.getItem('scroll')) {
      documentElement.scrollTop = Number(sessionStorage.getItem('scroll'));
      sessionStorage.removeItem('scroll');
    }
  });
  const retryReady = async (retries = 0) => {
    if (retries >= 50) return;
    await new Promise((resolve) => setTimeout(resolve, 50 + retries * 10));
    await fetch('/ready').catch(() => retryReady(retries + 1));
  };

  fetch('/closing').catch(async () => {
    await retryReady();
    sessionStorage.setItem('scroll', String(documentElement.scrollTop));
    location.reload();
  });
}
