import { component } from '~/utilities/web-components';
import { visible } from './utilities/visible';

interface CustomWindow extends Window {
  hbspt: any;
}

const HUBSPOT_SCRIPT_URL = '//js.hsforms.net/forms/embed/v2.js';

const Component = component('x-contact-form')<{ target: string; portalId: string; formId: string }>(
  async (self, attributes) => {
    await visible(self, { rootMargin: '150px' });
    if (document.querySelector(`script[src="${HUBSPOT_SCRIPT_URL}"]`)) {
      return;
    }
    const script = document.createElement('script');
    script.src = HUBSPOT_SCRIPT_URL;
    script.async = true;
    document.body.append(script);

    script.addEventListener('load', () => {
      const customWindow = window as unknown as CustomWindow;
      if (customWindow.hbspt) {
        customWindow.hbspt.forms.create({
          portalId: attributes.portalId,
          formId: attributes.formId,
          target: attributes.target,
        });
      }
    });
  },
);

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
