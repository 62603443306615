import { component } from '~/utilities/web-components';

type TabsAttributes = {
  initialTab?: number;
};

const Tabs = component('x-tabs')<TabsAttributes>((self, attributes) => {
  const tabs = [...self.querySelectorAll('[role=tab]')];
  const tabPanels = [...self.querySelectorAll('[role=tabpanel]')];

  // Adding type of event because I can't remove listener if the param is only number
  // This resolves TS error when removing listener.
  const openTab = (index: number | Event) => {
    if (typeof index === 'number') {
      const target = self.querySelectorAll<HTMLElement>('[role="tabpanel"]')[index];
      hideTabsContent();
      target?.classList.remove('max-h-0', '!p-0');
      target?.classList.add('max-h-[1000px]');
    }
  };

  const hideTabsContent = () => {
    tabPanels.forEach((item) => {
      item.classList.remove('max-h-[1000px]');
      item.classList.add('max-h-0', 'overflow-hidden', 'transition-all', '!p-0', 'duration-500');
    });
  };

  tabs.forEach((tab, index) => {
    tab.addEventListener('click', () => openTab(index));
  });

  hideTabsContent();

  if (attributes.initialTab) {
    openTab(+attributes.initialTab - 1);
  }

  return () => {
    tabs.forEach((tab) => {
      tab.removeEventListener('click', openTab);
    });
  };
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Tabs> {}
  }
}
