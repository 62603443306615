import { component } from '~/utilities/web-components';
import { Config, NormalizedProductVariant } from '~/components/variant-selector';

const enableRadio = (radio: Element, enable: boolean) => {
  const radioElement = radio as HTMLInputElement;

  radioElement.disabled = !enable;
  radioElement.parentElement?.classList.toggle('opacity-25', !enable);
};

const Component = component('x-variant-selector')<{
  variants: NormalizedProductVariant[];
  config: Config;
  translations: Record<'add_to_cart' | 'out_of_stock', string>;
}>((self, attributes) => {
  const config = JSON.parse(attributes.config) as Config;
  const normalizeProductVariants = JSON.parse(attributes.variants);
  const translations = JSON.parse(attributes.translations);
  const init = () => {
    initialVariantsSelection();
    variantRadioHandler();
  };

  const setConfigSelectedByInputName = (inputName: string, inputValue: string) => {
    for (const variantConfig of Object.values(config)) {
      if (variantConfig.name === inputName) {
        variantConfig.selected = inputValue;
      }
    }
  };

  const getOppositeRadioConfig = (inputName: string) => {
    for (const variantConfig of Object.values(config)) {
      if (variantConfig.name != inputName) {
        return variantConfig;
      }
    }
  };

  const filterAvailableVariants = () => {
    return normalizeProductVariants.filter((variant: { quantity: number }) => variant.quantity > 0);
  };

  const findVariantBySelectedSingleChoice = (firstSelectedVariant: string[]) => {
    return normalizeProductVariants.find(
      (variant: any) => variant[firstSelectedVariant[0]] === firstSelectedVariant[1] && variant['quantity'] > 0,
    );
  };

  const findVariantBySelectedChoices = (firstSelectedVariant: string[], secondSelectedVariant: string[]) => {
    return normalizeProductVariants.find(
      (variant: any) =>
        variant[firstSelectedVariant[0]] === firstSelectedVariant[1] &&
        variant[secondSelectedVariant[0]] === secondSelectedVariant[1] &&
        variant['quantity'] > 0,
    );
  };

  const getOtherRadioElements = (inputName: string) => {
    for (const variantConfig of Object.values(config)) {
      if (variantConfig.name != inputName) {
        return [...self.querySelectorAll('[data-variant=' + variantConfig.dataVariant + ']')];
      }
    }
  };

  const radioStateHandler = (inputName: string, value: string) => {
    const otherRadioElements = getOtherRadioElements(inputName);

    if (otherRadioElements) {
      otherRadioElements.forEach((otherRadio) => {
        const otherRadioElement = otherRadio.querySelector<HTMLInputElement>(`input`);

        if (otherRadioElement) {
          const stock = normalizeProductVariants.find(
            (variant: { [x: string]: string }) =>
              variant[inputName] === value && variant[otherRadioElement.name] === otherRadioElement.value,
          );

          enableRadio(otherRadioElement, stock?.quantity > 0);
        }
      });
    }
  };

  const radioFilterHandler = (radioElementName: string, radioElementValue: string) => {
    const previousSelectedVariantConfig = getOppositeRadioConfig(radioElementName);
    const variantMatchFound = findVariantBySelectedChoices(
      [radioElementName, radioElementValue],
      [previousSelectedVariantConfig.name, previousSelectedVariantConfig.selected],
    );
    const previousSelectedOppositeVariant = self.querySelector<HTMLInputElement>(
      `input[name=${previousSelectedVariantConfig.name}][value="${previousSelectedVariantConfig.selected}"]`,
    );

    if (variantMatchFound) {
      if (previousSelectedOppositeVariant) {
        previousSelectedOppositeVariant.checked = true;
      }
      setSelectedVariantId(variantMatchFound['id']);
      addToCartStateHandler(true);
    } else {
      const nextSelectedVariant = findVariantBySelectedSingleChoice([radioElementName, radioElementValue]);

      if (nextSelectedVariant) {
        const nextSelectedVariantElement = self.querySelector<HTMLInputElement>(
          `input[name=${previousSelectedVariantConfig.name}][value="${
            nextSelectedVariant[previousSelectedVariantConfig.name]
          }"]`,
        );

        if (nextSelectedVariantElement) {
          enableRadio(nextSelectedVariantElement, true);
          nextSelectedVariantElement.checked = true;
          setConfigSelectedByInputName(
            previousSelectedVariantConfig.name,
            nextSelectedVariant[previousSelectedVariantConfig.name],
          );
          radioStateHandler(
            previousSelectedVariantConfig.name,
            nextSelectedVariant[previousSelectedVariantConfig.name],
          );
          setSelectedVariantId(nextSelectedVariant['id']);
          addToCartStateHandler(true);
        }
      } else {
        if (previousSelectedOppositeVariant) {
          previousSelectedOppositeVariant.checked = false;
          previousSelectedVariantConfig.selected = '';
        }

        addToCartStateHandler(false);
      }
    }
  };

  const variantRadioHandler = () => {
    for (const variantConfig of Object.values(config)) {
      const variantRadios = [
        ...self.querySelectorAll<HTMLDivElement>('[data-variant=' + variantConfig.dataVariant + ']'),
      ];

      variantRadios.forEach((variantRadio) => {
        variantRadio.addEventListener('click', (event: { preventDefault: () => void }) => {
          event.preventDefault();

          const radioElement = variantRadio.querySelector<HTMLInputElement>('input[type="radio"]');
          if (radioElement) {
            enableRadio(radioElement, true);
            radioElement.checked = true;
            radioElement.focus();

            if (Object.keys(config).length > 1) {
              setConfigSelectedByInputName(radioElement.name, radioElement.value);
              radioStateHandler(radioElement.name, radioElement.value);
              radioFilterHandler(radioElement.name, radioElement.value);
            } else {
              const checkAvailability = findVariantBySelectedSingleChoice([radioElement.name, radioElement.value]);

              if (checkAvailability) {
                setSelectedVariantId(checkAvailability.id);
              }
              addToCartStateHandler(checkAvailability !== undefined);
            }
          }
        });
      });
    }
  };

  const addToCartStateHandler = (enable: boolean) => {
    const addToCart = self.querySelector<HTMLInputElement>('button[type="submit"]');

    if (addToCart) {
      addToCart.disabled = !enable;
      addToCart.textContent = enable ? translations.add_to_cart : translations.out_of_stock;
    }
  };

  const setSelectedVariantId = (selectedVariantId: null | string) => {
    const variantIdInputElement = self.querySelector<HTMLInputElement>('input[name="variantId"]');

    const activeVariant = normalizeProductVariants.find(({ id }: { id: string }) => id === selectedVariantId);

    if (variantIdInputElement && selectedVariantId) {
      variantIdInputElement.setAttribute('value', selectedVariantId);
      // eslint-disable-next-line unicorn/prefer-dom-node-dataset
      variantIdInputElement.setAttribute(
        'data-elb-product',
        `${activeVariant.size > 0 ? 'size:' + activeVariant.size.toLowerCase() : ''};${
          activeVariant.color ? 'color:' + activeVariant.color.toLowerCase() : ''
        }`,
      );
    }
  };

  const initialVariantsSelection = () => {
    const filteredAvailableVariants = filterAvailableVariants();

    if (filteredAvailableVariants.length > 0) {
      const defaultVariant = filteredAvailableVariants[0];
      setSelectedVariantId(defaultVariant.id);

      for (const variantConfig of Object.values(config)) {
        const radioElement = self.querySelector<HTMLInputElement>(
          `input[name=${variantConfig.name}][value="${defaultVariant[variantConfig.name]}"]`,
        );

        if (radioElement) {
          enableRadio(radioElement, true);
          radioElement.checked = true;
          variantConfig.selected = defaultVariant[variantConfig.name];
          radioStateHandler(variantConfig.name, defaultVariant[variantConfig.name]);
        }
      }
    } else {
      addToCartStateHandler(false);
    }
  };

  init();
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
