import { visit } from '@hotwired/turbo';
import { component, html } from '~/utilities/web-components';
import type { API } from 'nouislider';
import 'nouislider/dist/nouislider.css';
import { visible } from './utilities/visible';

interface TargetElement extends HTMLElement {
  noUiSlider?: API;
}

const Component = component('x-price-filter')<{
  absoluteMin: number;
  absoluteMax: number;
  max: number;
  min: number;
  url: string;
  currencySymbol: string;
}>(async (self, attributes) => {
  await visible(self);
  const { default: noUiSlider } = await import('nouislider');
  const min = Number(attributes.min);
  const max = Number(attributes.max);
  const currencySymbol = attributes.currencySymbol ?? '€';
  const content = html`
    <div style="min-height: 84px">
      <div class="slider px-4 text-primary-500"></div>
      <div class="mt-4">
        <div class="inline-block border border-gray-200 py-1 px-2">
          ${currencySymbol}&nbsp;<input type="text" name="price-min" value="${min}" class="w-20 border-none" />
        </div>
        <div class="float-right inline-block border border-gray-200 py-1 px-2 text-right">
          ${currencySymbol}&nbsp;<input type="text" name="price-max" value="${max}" class="w-20 border-none" />
        </div>
      </div>
    </div>
  `;
  self.replaceChildren(content);
  const minInput = self.querySelector<HTMLInputElement>('[name="price-min"]')!;
  const maxInput = self.querySelector<HTMLInputElement>('[name="price-max"]')!;
  const slider = noUiSlider.create(self.querySelector<TargetElement>('.slider')!, {
    start: [min, max],
    connect: true,
    step: 1,
    margin: 1,
    range: { min: Number(attributes.absoluteMin), max: Number(attributes.absoluteMax) },
  });
  slider.on('update', ([min, max]) => {
    minInput.value = Math.floor(Number(min)) + '';
    maxInput.value = Math.ceil(Number(max)) + '';
  });
  slider.on('set', ([min, max]) => {
    visit(
      attributes.url.replace('__MIN__', Math.floor(Number(min)) + '').replace('__MAX__', Math.ceil(Number(max)) + ''),
    );
  });
  const updateSlider = () => slider.set([minInput.value, maxInput.value]);
  minInput.addEventListener('change', updateSlider);
  maxInput.addEventListener('change', updateSlider);

  return () => {
    slider.destroy();
  };
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
