import type { ErrorPayload } from '~/utilities/router/frontend-error-logger';

const registeredErrors = new Set();
const errors: ErrorPayload['errors'] = [];

window.addEventListener('error', (event) => {
  const id = `${event.filename}:${event.lineno}:${event.colno}`;
  if (registeredErrors.has(id) || errors.length >= 5) {
    return;
  }
  registeredErrors.add(id);
  errors.push({
    reqId: document.body.dataset.requestId,
    releaseId: __RELEASE_ID__,
    releaseDate: __RELEASE_DATE__,
    url: window.location.href,
    message: event.message,
    filename: event.filename,
    line: event.lineno,
    column: event.colno,
    stack: event.error ? event.error.stack : undefined,
  });
});

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden' && errors.length > 0) {
    navigator.sendBeacon('/_errors', new Blob([JSON.stringify({ errors })], { type: 'application/json' }));
    errors.length = 0;
  }
});
