import { slugify } from '~/utilities/slugify';
import { openDB } from 'idb';

export const setFavColorCookie = async () => {
  const DB_NAME = 'tracking';
  const db = await openDB(DB_NAME, 2);

  // TODO: #132 Refactor preferredColors function to always use hit.data.color as an array
  const preferredColors: Record<string, number> = (await db.getAllFromIndex('hits', 'date')).reduce((agg, hit) => {
    if (hit.data?.color) {
      const colors: string[] = Array.isArray(hit.data.color)
        ? hit.data.color.map((currentColor: string) => slugify(currentColor))
        : [slugify(hit.data.color)];

      colors.forEach((color: string) => {
        agg[color] = (agg[color] ?? 0) + 1;
      });
    }

    return agg;
  }, {});

  let favColor: string | null = null;
  let favColorCount = Number.NEGATIVE_INFINITY;

  for (const [colorKey, value] of Object.entries(preferredColors)) {
    if (value > favColorCount) {
      favColor = colorKey;
      favColorCount = value;
    }
  }

  if (favColorCount >= 3) {
    const getCookie = (name: string) => {
      return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, '');
    };

    if (getCookie('favColor') != favColor) {
      document.cookie = `favColor=${favColor}; path=/`;
    }
  }
};
