import { visit } from '@hotwired/turbo';
import { component } from '~/utilities/web-components';

const Component = component('x-redirect')<{ path: string }>((_self, attributes) => {
  visit(attributes.path);
});

declare global {
  namespace JSX {
    interface IntrinsicElements extends Id<typeof Component> {}
  }
}
